import styled from 'styled-components';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const FormStyles = styled.div`
  z-index: 1;
  margin-top: 24px;
  margin-bottom: 48px;
  position: relative;
  width: 100%;

  &.hero-form {
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 8px 80px rgba(106, 114, 158, 0.2);
    border-radius: 12px;

    @media (min-width: 575.98px) {
      padding: 48px;
    }

    .form-title {
      ${font('display', 'sm', '700')}
      color: #1D2030 !important;
      margin-bottom: 16px;

      &.with-subhead {
        margin-bottom: 8px !important;
      }
    }

    .text-subhead p {
      color: #171e2a !important;
    }

    .form-group {
      margin-bottom: 16px;
    }

    .form-label {
      ${font('text', 'md', '500')}
      color: #4d5061;
      text-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px #e9f2ff;
      margin-bottom: 6px;

      &.required {
        &:after {
          content: '*';
          color: #d93030;
        }
      }
    }

    .MuiInputBase-formControl {
      margin-bottom: 16px;
    }

    input {
      ${font('text', 'lg', '400')}
      background: #ffffff;
      border: 1px solid #c0c3d1;
      box-shadow: 0px 1px 2px rgba(19, 48, 205, 0.05);
      border-radius: 8px;
      padding: 10px 14px;
      color: #1d2030;
      height: 47px;
      margin-bottom: 16px;

      &:focus {
        border: 1px solid #7489ff;
        box-shadow: 0px 0px 0px 4px #e9e9ff;
      }

      &.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
    }

    .nextbtn,
    button {
      background: #00a081;
      box-shadow: 0px 1px 2px rgba(19, 48, 205, 0.05);
      border-radius: 6px;
      border: none;
      box-shadow: 0px 1px 2px rgba(19, 48, 205, 0.05);
      border-radius: 6px;
      height: 47px;
      ${font('text', 'lg', '700')}
      color: #ffffff;
    }

    .checkbox {
      label {
        display: flex;
        gap: 10px;
        margin: 10px 0;
        position: relative;

        &:has(:checked) {
          &::before {
            content: '';
            display: block;
            width: 16px;
            height: 8px;
            position: absolute;
            top: 9px;
            left: 7px;
            transform: rotate(-45deg);
            border-bottom: 3px solid ${colors.base.white};
            border-left: 3px solid ${colors.base.white};
          }
        }

        input[type='checkbox'] {
          appearance: none;
          border: 1px solid ${colors.gray[300]};
          border-radius: 6px;
          flex: 0 0 20px;
          width: 30px;
          height: 30px;

          &:checked {
            background: ${colors.primary[500]};
          }
        }

        span {
          display: block;
          padding-top: 2px;
        }
      }
    }

    .MuiInputBase-root {
      height: 45px;
      padding: 10px 14px;
      border-radius: 8px;

      .MuiSelect-root {
        margin-top: -5px;
        padding: 0;
      }

      .MuiSelect-icon {
        top: calc(50% - 15px);
      }

      .MuiSelect-select {
        &:focus {
          background-color: transparent;
        }
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #7489ff;
          box-shadow: 0px 0px 0px 4px #e9e9ff;
        }
      }

      legend {
        display: none;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  transition: width 0.3s ease;
  background-color: #c0c3d1;
  border-radius: 6px;
  overflow: hidden;

  .active {
    height: 100%;
    background-color: #1c3eff;
  }
`;

export const ProgressStep = styled.div`
  margin-top: 32px;
  position: relative;

  .progress-title {
    ${font('display', 'xs', '500')}
    color: #6f7283;
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const BackBtn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${font('text', 'xs', '700')}
  color: #86899a;

  svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  span {
    vertical-align: middle;
  }
`;
